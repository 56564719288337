import React from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import times from 'lodash/times';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import clone from 'lodash/clone';
import { Row, Column } from '@audacious/components/components/Grid';
import Button from '@audacious/components/components/Button';
import {
    PageTitle,
    PageContainer,
} from '@audacious/components/components/Page';
import { Paragraph } from '@audacious/components/components/Typography';
import isNil from 'lodash/isNil';
import Response from './response';

function buildResponses(numberOfRequiredQuestions) {
    return times(numberOfRequiredQuestions, index => ({
        index,
        questionId: null,
        questionText: null,
        answer: null,
    }));
}

class SetSecurityQuestions extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        const { numberOfRequiredQuestions } = props;

        this.state = {
            responses: buildResponses(numberOfRequiredQuestions),
            usedQuestions: {},
        };
    }

    componentDidMount() {
        const { 
            fluxibleContext: {
                service: {
                    retrieveAvailableQuestions,
                }
            }
        } = this.props;

        retrieveAvailableQuestions();
    }

    handleChange(response) {
        const { usedQuestions } = this.state;
        let { responses } = this.state;

        const currentResponse = responses[response.index];

        if (!isNil(currentResponse.questionId)) {
            usedQuestions[currentResponse.questionId] = false;
        }

        usedQuestions[response.questionId] = true;

        responses = clone(responses);

        responses[response.index] = response;

        this.setState({
            responses,
            usedQuestions: {
                ...usedQuestions,
            },
        });
    }

    // eslint-disable-next-line class-methods-use-this
    handleSubmit() {
        const {
            fluxibleContext: {
                service: {
                    resolveSetSecurityQuestions
                },
            },
         } = this.props;

        const { responses } = this.state;

        const responseData = map(responses, response => {
            const { questionId, questionText, answer } = response;

            return {
                questionId,
                question: questionText,
                answer,
            };
        });

        resolveSetSecurityQuestions({
            data: responseData,
        });
    }

    canSubmit() {
        const {
            status: { processing },
        } = this.props;

        const { responses } = this.state;

        if (processing) {
            return false;
        }

        let canSubmit = true;

        forEach(responses, response => {
            const { questionId, answer, questionText } = response;

            if (
                isNil(questionId) ||
                questionId.length <= 0 ||
                isNil(answer) ||
                answer.length <= 0 ||
                isNil(questionText) ||
                questionText.length <= 0
            ) {
                canSubmit = false;
            }

            return canSubmit;
        });

        return canSubmit;
    }

    renderResponses() {
        const {
            availableQuestions: { questions },
        } = this.props;

        const { responses, usedQuestions } = this.state;

        return map(responses, (response, index) => (
                <Row key={index} gutter="16">
                    <Column>
                        <Response
                            key={response.index}
                            response={response}
                            availableQuestions={questions}
                            usedQuestions={usedQuestions}
                            onChange={this.handleChange}
                        />
                    </Column>
                </Row>
            ));
    }

    render() {
        const {
            availableQuestions: { isLoaded },
        } = this.props;

        let content = null;

        if (isLoaded) {
            content = (
                <>
                    <Row>
                        <Column>
                            <Paragraph size="md">
                                Please select your security questions and
                                answers
                            </Paragraph>
                        </Column>
                    </Row>
                    {this.renderResponses()}
                    <Row gutter="16">
                        <Column>
                            <Button
                                id="set-security-questions-btn"
                                variant="fill"
                                color="primary"
                                disabled={!this.canSubmit()}
                                onClick={this.handleSubmit}
                            >
                                Save
                            </Button>
                        </Column>
                    </Row>
                </>
            );
        }

        return (
            <>
                <PageTitle
                    id="set-password-page-title"
                    pageName="Security Questions"
                />
                <PageContainer
                    className="select-questions-page"
                    asCard={isLoaded}
                    isLoading={!isLoaded}
                >
                    {content}
                </PageContainer>
            </>
        );
    }
}

SetSecurityQuestions.propTypes = {
    availableQuestions: PropTypes.shape({
        questions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                question: PropTypes.string.isRequired,
            }),
        ),
        // eslint-disable-next-line react/forbid-prop-types
        failure: PropTypes.object,
        isLoaded: PropTypes.bool.isRequired,
    }).isRequired,
    status: PropTypes.shape({
        processing: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        failure: PropTypes.object,
    }).isRequired,
    numberOfRequiredQuestions: PropTypes.number.isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
			resolveSetSecurityQuestions: PropTypes.func.isRequired,
            retrieveAvailableQuestions: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

SetSecurityQuestions.defaultProps = {};

export default connectToStores(
    applyFluxibleContext(SetSecurityQuestions),
    ['Requirements', 'ApplicationContext'],
    context => {
        const requirementsStore = context.getStore('Requirements');

        const availableQuestions = requirementsStore.getAvailableQuestions();

        const status = requirementsStore.getRequirementsStatus();

        const applicationContextStore = context.getStore('ApplicationContext');
        const numberOfRequiredQuestions = applicationContextStore.config(
            'security.numberOfRequiredQuestions',
        );

        return {
            availableQuestions,
            status,
            numberOfRequiredQuestions,
        };
    },
);

import React, { memo, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@audacious/components/components/TextInput';
import SelectInput from '@audacious/components/components/SelectInput';
import { Row, Column } from '@audacious/components/components/Grid';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

function Response(props) {
    const { usedQuestions, onChange, response, availableQuestions } = props;

    const {
        questionId: responseQuestionId,
        questionText: responseText,
        answer: responseAnswer,
        index: questionNumber,
    } = response;

    const [touched, setTouched] = useState(false);

    const handleCustomTextChange = useCallback(
        questionText => {
            onChange({
                ...response,
                questionText,
            });
        },
        [onChange, response],
    );

    const handleSelectedQuestionChange = useCallback(
        questionId => {
            const newResponse = {
                ...response,
                questionId: null,
                questionText: null,
            };

            if (!isNil(questionId)) {
                newResponse.questionId = questionId;

                const targetQuestion = availableQuestions.getById(questionId);

                if (!targetQuestion.isCustom) {
                    newResponse.questionText = targetQuestion.question;
                }
            }

            onChange(newResponse);
        },
        [onChange, response, availableQuestions],
    );

    const handleSelectedAnswerChange = useCallback(answer => {
        onChange({
            ...response,
            answer,
        });
    });

    const handleBlur = useCallback(() => {
        setTouched(true);
    });

    const targetQuestion = useMemo(() => !isNil(responseQuestionId)
        ? availableQuestions.getById(responseQuestionId)
        : {}, [responseQuestionId, availableQuestions]);

    const questionOptions = availableQuestions.reduce((acc, question) => {
        const { id: questionId, question: questionText } = question;

        if (!usedQuestions[questionId] || questionId === responseQuestionId) {
            acc.push({
                label: questionText,
                value: {
                    id: questionId,
                },
            });
        }

        return acc;
    }, []);

    const responseTextEmpty = isEmpty(responseText);

    const customTextMessages = useMemo(() => responseTextEmpty
            ? {
                  invalid: ['Please write a question.'],
              }
            : null, [responseTextEmpty]);

    const customTextElement = useMemo(() => {
        if (!targetQuestion.isCustom) {
            return null;
        }

        return (
            <Column width={[null, null, '6']}>
                <TextInput
                    id={`response-${questionNumber}-text`}
                    label="Question"
                    className="custom-question"
                    value={responseText}
                    onChange={handleCustomTextChange}
                    messages={customTextMessages}
                    invalid={!isNil(customTextMessages)}
                />
            </Column>
        );
    }, [
        targetQuestion.isCustom,
        responseText,
        questionNumber,
        customTextMessages,
    ]);

    const questionSelectMessages = useMemo(() => responseAnswer && !responseQuestionId
            ? {
                  invalid: ['Please select a question'],
              }
            : null, [responseAnswer, responseQuestionId]);

    const answerEmpty = isEmpty(responseAnswer);

    const answerTextMessages = useMemo(() => touched && !isNil(responseQuestionId) && answerEmpty
            ? {
                  invalid: ['Please write an answer.'],
              }
            : null, [touched, responseQuestionId, answerEmpty]);

    return (
        <Row gutter="8">
            <Column
                width={[null, null, '6']}
                rightOffset={targetQuestion.isCustom ? [null, null, '6'] : null}
            >
                <SelectInput
                    id={`response-${questionNumber}-question`}
                    value={responseQuestionId}
                    label={`Select Question ${questionNumber + 1}`}
                    options={questionOptions}
                    onChange={handleSelectedQuestionChange}
                    keyPath="value.id"
                    optionTextPath="label"
                    messages={questionSelectMessages}
                    invalid={!isNil(questionSelectMessages)}
                />
            </Column>
            {customTextElement}
            <Column width={[null, null, '6']}>
                <TextInput
                    id={`response-${questionNumber}-answer`}
                    label="Answer"
                    className="answer"
                    value={responseAnswer}
                    onChange={handleSelectedAnswerChange}
                    onBlur={handleBlur}
                    messages={answerTextMessages}
                    invalid={!isNil(answerTextMessages)}
                />
            </Column>
        </Row>
    );
}

Response.propTypes = {
    response: PropTypes.shape({
        index: PropTypes.number.isRequired,
        questionId: PropTypes.string.isRequired,
        questionText: PropTypes.string,
        answer: PropTypes.string.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    availableQuestions: PropTypes.object.isRequired,
    usedQuestions: PropTypes.objectOf(PropTypes.bool).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default memo(Response);
